<template>
    <div class="master-toko">
        <header-page>
            <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
            <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
            <span class="text-dark fw-bold-400 size14">Rekening</span>
        </header-page>

        <b-container>
            <BankAccounts />
        </b-container>
    </div>
</template>


<script>
import { BContainer } from 'bootstrap-vue'

import HeaderPage from '@/components/HeaderPage.vue'
import BankAccounts from '@/components/Master/Toko/BankAccounts.vue'

export default {
    title() {
        return 'Master Daftar Rekening'
    },
    components: {
        BContainer,
        HeaderPage,
        BankAccounts
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.master-toko {
    button.bg-white {
        &:focus {
            background-color: transparent !important;
        }
    }
}
</style>
